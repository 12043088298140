import $ from 'jquery';
import 'what-input';
import Swiper from 'swiper/bundle';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

$(document).ready(function(){
  $(document).foundation();

  $('[data-drilldown]').on('open.zf.drilldown hide.zf.drilldown closed.zf.drilldown', function() {
    var animationDuration = 300;
    var animationEasing = 'swing';
    $('.menu.drilldown').stop(true).animate({
      scrollTop: 0
    }, animationDuration, animationEasing);
  });

  $('.js-drilldown-back').each(function(){
    var back_text = $(this).parent().closest('.is-drilldown-submenu-parent').find('> a').text();
    $(this).html('<span>'+back_text+'</span>');
  });


  // $(window).on('changed.zf.mediaquery', function() {
  //   $('.is-dropdown-submenu.invisible').removeClass('invisible');
  // });
  jQuery(document).ready(function(){
    jQuery('#header-container-menu').removeClass('hide-until-page-load');
  });
  
  var position = $(window).scrollTop(); 
  var is_autoplay = true;
  var main_header_height = $('.main_header_top_container').outerHeight();
  var swiper_alto_height = $('.slider-alto-fixed-height').outerHeight() + $('.slider-alto-fixed-height').offset().top;
  $(document.body).on('touchmove', make_sticky); // for mobile
  $(window).on('scroll', make_sticky); 

  function make_sticky() {
    var scroll_from_top = $(window).scrollTop();
    
    if (scroll_from_top > swiper_alto_height && is_autoplay == true){
      
      swiper_container_hero_image.autoplay.stop();
      is_autoplay = false;
      //console.log('stop');
    } 
    if (scroll_from_top < swiper_alto_height && is_autoplay == false){
      swiper_container_hero_image.autoplay.start();
      is_autoplay = true;
      //console.log('start');
    }

    if (scroll_from_top > main_header_height) {
      $('body').addClass('header-sticky');
      $('body').addClass('is-fixed-logo');
      //scroll down
      if(scroll_from_top > position){
        $('body').removeClass('header-sticky-show');
        
        if ($(".container_menu .menu-header.title-bar .toggle-search").is(':checked')){
          $(".container_menu .menu-header.title-bar .toggle-search").click();
        }

      } else {
        $('body').addClass('header-sticky-show');
      }

    } else {

      $('body').removeClass('header-sticky-show');
      $('body').removeClass('header-sticky');
      $('body').removeClass('is-fixed-logo');
    }

    position = scroll_from_top;
  }


  $('#custom-menu-icon').on('click', function() {
    $('html').toggleClass("menu-open");
  });

  $('.js-drilldown-back').each(function(){
    var backTxt = $(this).parent().closest('.is-drilldown-submenu-parent').find('> a').text();
    $(this).html('<span>'+backTxt+'</span>');
  });

  let labels = [] 
  $.each($('.hero-slide-title .use-for-pagination'), function(index) {
     labels.push($( this ).text());
  });;

  var swiper_container_hero_image = new Swiper('.swiper-hero-image', {
      slidesPerView: 1,
      spaceBetween: 0,
      autoplay: {
        delay: 5000,
      },
      effect: "creative",
      creativeEffect: {
        prev: {
          shadow: true,
          translate: ["-100vw", 0, 0],
        },
        next: {
          translate: ["100%", 0, 0],
        },
      },
      breakpoints: {
        1024: {
          slidesPerView: "auto",
        }
      },
      navigation: {
        nextEl: ".swiper-hero-button-next",
        prevEl: ".swiper-hero-button-prev",
      },
      pagination: {
        el: ".swiper-hero-pagination-title",
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + (labels[index]) + '</span>';
        },
      },
  });

  var swiper_container_hero_text = new Swiper('.swiper-hero-text', {
      slidesPerView: 1,
      effect: 'fade',
      speed: 5000,
      fadeEffect: {
        crossFade: true
      },
      pagination: {
        el: ".swiper-hero-pagination",
        clickable: true,
        renderBullet: function (index, className) {
          return '<div class="' + className + '"><span></span></div>';
        },
      },
  });

  
  swiper_container_hero_text.on('slideChange', function () {
    
    swiper_container_hero_image.slideTo(this.activeIndex);
  });

  swiper_container_hero_image.on('slideChange', function () {
    
    swiper_container_hero_text.slideTo(this.activeIndex);
  });


  var swiper_container_scelte = new Swiper('.swiper-scelte', {
      slidesPerView: "auto",
      spaceBetween: 30,
      navigation: {
        nextEl: ".swiper-scelte-button-next",
        prevEl: ".swiper-scelte-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: function (index, className) {
          return '<div class="' + className + '"><span></span></div>';
        },
      },
  });

  var swiper_container_media = new Swiper('.swiper-media', {
      slidesPerView: "auto",
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-media-button-next",
        prevEl: ".swiper-media-button-prev",
      },
      pagination: {
        el: ".swiper-media-pagination",
        clickable: true,
        renderBullet: function (index, className) {
          return '<div class="' + className + '"><span></span></div>';
        },
      },
  });

  var swiper_container_ondacinema = new Swiper('.swiper-ondacinema', {
      slidesPerView: "auto",
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-cinema-button-next",
        prevEl: ".swiper-cinema-button-prev",
      },
      pagination: {
        el: ".swiper-ondacinema-pagination",
        clickable: true,
        renderBullet: function (index, className) {
          return '<div class="' + className + '"><span></span></div>';
        },
      },
  });

  var swiper_container_dischi = new Swiper('.swiper-container-dischi', {
      slidesPerView: 1,
      //effect: "fade",
      spaceBetween: 0,
      // fadeEffect: {
      //   crossFade: true
      // },
      // navigation: {
      //   nextEl: ".swiper-dischi-button-next",
      //   prevEl: ".swiper-dischi-button-prev",
      // },
      pagination: {
        el: ".swiper-dischi-pagination",
        clickable: true,
        renderBullet: function (index, className) {
          return '<div class="' + className + '"><span></span></div>';
        },
      },
  });

  var swiper_container_pietre = new Swiper('.swiper-container-pietre', {
      slidesPerView: 1,
      //effect: "fade",
      spaceBetween: 0,
      // fadeEffect: {
      //   crossFade: true
      // },
      // navigation: {
      //   nextEl: ".swiper-pietre-button-next",
      //   prevEl: ".swiper-pietre-button-prev",
      // },
      pagination: {
        el: ".swiper-pietre-pagination",
        clickable: true,
        renderBullet: function (index, className) {
          return '<div class="' + className + '"><span></span></div>';
        },
      },
  });

  var pager_box_basso = 1;
  var last_pager = $('.box_basso .box.cell').last().data("pager");
  $("#load_more_homebox").click(function(e) {
    
    $('.box_basso *[data-pager='+pager_box_basso+']').removeClass('hide-for-now');

    if (last_pager == pager_box_basso){
      $("#load_more_homebox").addClass('hide');
    }
    pager_box_basso = pager_box_basso+1;

    e.preventDefault();
  });

  var pager_recensioni = 1;
  var last_pager_recensioni = $('.box_basso .box.cell').last().data("pager");
  $("#load_more_recensioni").click(function(e) {
    
    $('.container_recensioni *[data-pager='+pager_recensioni+']').removeClass('hide-for-now');

    pager_recensioni = pager_recensioni+1;
    if (last_pager_recensioni == pager_recensioni){
      $("#load_more_recensioni").addClass('hide');
    }

    e.preventDefault();
  });

  

  // $('.discografia_list tr').each(function(){
   
  //   //console.log(row_content + " " + $(this).children('img').length)
  //   $(this).children('td').each(function)
  //   if ($(this).html() == '') {
  //     $(this).remove();
  //   }

  // });

  //$('.discografia_list').find('img').closest('td').addClass('image_row');
  if($('.discografia_list').length > 0 && $('.discografia_list table').length > 0){
    $('.discografia_list').find('tr td').addClass(function(){
      
      $(this).removeAttr("align");
      //console.log($(this).text());
      if (/^\d+$/.test($.trim($(this).text())) || /^\d+\.\d+$/.test($.trim($(this).text())) || /^\d+\,\d+$/.test($.trim($(this).text()))) {
        var voto = $.trim($(this).text());
        //console.log(voto);
        $(this).empty();
        $(this).text(voto);
        return parseInt($.trim($(this).text())) >= 8 ? "red" : "gray";
      }
    });

    $('.discografia_list table').removeAttr(Object.values($('.discografia_list table').get(0).attributes).map(attr => attr.name).join(' '));
    $('.discografia_list tr').removeAttr(Object.values($('.discografia_list tr').get(0).attributes).map(attr => attr.name).join(' '));
    $('.discografia_list td').removeAttr(Object.values($('.discografia_list td').get(0).attributes).map(attr => attr.name).join(' '));
    $('.discografia_list td').each(function(){
      var image_src = $(this).find('img').attr('src');
      if (image_src){
        $(this).addClass('image_row');
        if (image_src.indexOf('recensioni') != -1){
          $(this).closest('tr').addClass('recensioni');
          //$(this).closest('tr').attr("tooltip", "Recensione");
          $(this).closest('tr').append('<img alt="Consigliato" title="Consigliato da OR" class="tooltip_image" src="/assets_or2022/img/bollini_recensioni.svg" class="tooltip">');
        } else {
          $(this).closest('tr').addClass('pietre');
          $(this).closest('tr').append('<img alt="Pietra miliare" title="Pietra miliare" class="tooltip_image" src="/assets_or2022/img/bollini.svg" class="tooltip">');
          //$(this).closest('tr').attr("tooltip", "Pietra");
          
        }
      }
    });

    $('.discografia_list tr').each(function(){
      if ($(this).hasClass('pietre')){
        $(this).find()
      }
    });
    
  } 

  var swiper_container_artistisimili = new Swiper('.swiper-artistisimili', {
      slidesPerView: "auto",
      spaceBetween: 30,
      pagination: {
        el: ".swiper-simili-pagination",
        clickable: true,
        renderBullet: function (index, className) {
          return '<div class="' + className + '"><span></span></div>';
        },
      },
  });

  var swiper_container_ultimerecensioni = new Swiper('.swiper-ultimerecensioni', {
      slidesPerView: "auto",
      spaceBetween: 30,
      navigation: {
        nextEl: ".swiper-ultimerecensioni-button-next",
        prevEl: ".swiper-ultimerecensioni-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: function (index, className) {
          return '<div class="' + className + '"><span></span></div>';
        },
      },
  });

  var swiper_container_ultimeinterviste = new Swiper('.swiper-ultimeinterviste', {
      slidesPerView: "auto",
      spaceBetween: 30,
      navigation: {
        nextEl: ".swiper-ultimeinterviste-button-next",
        prevEl: ".swiper-ultimeinterviste-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: function (index, className) {
          return '<div class="' + className + '"><span></span></div>';
        },
      },
  });


  var swiper_container_ultimepietre = new Swiper('.swiper-pietre', {
      slidesPerView: "auto",
      spaceBetween: 30,
      navigation: {
        nextEl: ".swiper-pietre-button-next",
        prevEl: ".swiper-pietre-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: function (index, className) {
          return '<div class="' + className + '"><span></span></div>';
        },
      },
  });

  var swiper_container_ultimilive = new Swiper('.swiper-ultimilive', {
      slidesPerView: "auto",
      spaceBetween: 30,
      navigation: {
        nextEl: ".swiper-ultimilive-button-next",
        prevEl: ".swiper-ultimilive-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: function (index, className) {
          return '<div class="' + className + '"><span></span></div>';
        },
      },
  });

  var swiper_container_ultimemono = new Swiper('.swiper-ultimemono', {
      slidesPerView: "auto",
      spaceBetween: 30,
      navigation: {
        nextEl: ".swiper-ultimemomno-button-next",
        prevEl: ".swiper-ultimemomno-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: function (index, className) {
          return '<div class="' + className + '"><span></span></div>';
        },
      },
  });

  var dataTable = $("#table_recensioni_ajax");
  
  if ($("body#recensioni_lista").length > 0){

    var anno = dataTable.data("anno");
    var order = dataTable.data("order");
    var sezione = dataTable.data("sezione");
    var search = $('#ricerca input').val();
    if (anno){
      callDataRecensioniPage(anno, order, sezione, 0, search); // recupero i primi record
    }
    
    let path = window.location.href.split('?')[0]

    $('#select_categoria select').on('change', function() {
      var selected = $('#select_categoria select option:selected');
      //console.log(selected);
      if (selected.hasClass('goto')){
        window.location.assign(this.value);
      } else {
        $('#lista_form').submit();
      }
    });
  }

  if ($("body#pietremiliari_lista").length > 0){

    $('#select_categoria select').on('change', function() {
      var selected = $('#select_categoria select option:selected');
      //console.log(selected);
      if (selected.hasClass('goto')){
        window.location.assign(this.value);
      } else {
        $('#lista_form').submit();
      }
    });
  }

  if ($("body#classifiche").length > 0){

    $('#select_categoria select').on('change', function() {
      var selected = $('#select_categoria select option:selected');
      //console.log(selected);
      if (selected.hasClass('goto')){
        window.location.assign(this.value);
      } else {
        $('#lista_form').submit();
      }
    });
  }

  if ($("body#concerti").length > 0){

    $('#select_categoria select').on('change', function() {
      var selected = $('#select_categoria select option:selected');
      //console.log(selected);
      if (selected.hasClass('goto')){
        window.location.assign(this.value);
      } else {
        $('#lista_form').submit();
      }
    });
  }

  if ($("body#archivio").length > 0){

    $('#select_categoria select').on('change', function() {
      var selected = $('#select_categoria select option:selected');
      //console.log(selected);
      if (selected.hasClass('goto')){
        window.location.assign(this.value);
      } else {
        $('#lista_form').submit();
      }
    });
  }

  // function callDataRecensioniPage(anno, order, nrec, search) {
  //   var url = '/require/function_lista_ajax.inc.php'; 
  //   console.log('anno='+anno+'&order='+order+'&last_id='+nrec+'&search='+search);
  //   $.ajax({
  //     type: 'POST',
  //     url: url,
  //     data: 'anno='+anno+'&order='+order+'&last_id=' + nrec+'&search='+search,
  //     dataType: 'json',
  //     success: function(response) {
  //       if(response.length < 1) {
  //         //hideMasterLoading();
  //       } else {
  //         //$('#table_recensioni_ajax .table_body').append(response);
  //         mapList(response);
  //         callDataRecensioniPage(anno, order, nrec+1, search);
  //       }
  //     },
  //     error: function(error) {
  //       console.log(error);
  //     }
  //   });
  // }

  function callDataRecensioniPage(anno, order, sezione, nrec, search) {
      // recupero il numero delle righe presenti in pagina per valorizzare il limit della query
      var url = '/require/function_lista_ajax_v2.inc.php'; 
      $.ajax({
        type: 'POST',
        url: url,
        data: 'anno='+anno+'&order='+order+'&sezione='+sezione+'&last_id=' + nrec+'&search='+search,
        dataType: 'html',
        success: function(text_html) {
          if(text_html == '') {
          
          } else {
          
            $('#table_recensioni_ajax tbody').append(text_html);
            callDataRecensioniPage(anno, order, sezione, nrec+1, search);
          
          }
        },
        error: function(error) {
          alert('oh no' + error);
        }
      });
    }

    $('body#staff_people .links-container table td').each(function(){
      if($(this).find('img').length > 0){
        $(this).addClass('row_image');
      }
      if($(this).find('a').length > 0){
        $(this).addClass('row_link');
      }
    });

    $("#ricerca_recensioni_staff_cerca").on("keyup", function() {
      var value = $(this).val().toLowerCase();
      $("#table_recensioni_staff tr").filter(function() {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
      });
    });

    $("#ricerca_monoint_staff_cerca").on("keyup", function() {
      var value = $(this).val().toLowerCase();
      $("#table_monoint_staff tr").filter(function() {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
      });
    });

    $("#ricerca_livereport_staff_cerca").on("keyup", function() {
      var value = $(this).val().toLowerCase();
      $("#table_livereport_staff tr").filter(function() {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
      });
    });

    $("#ricerca_speciali_staff_cerca").on("keyup", function() {
      var value = $(this).val().toLowerCase();
      console.log(value);
      $("#speciali_main_container .speciali_container").filter(function() {
        $(this).toggle($(this).attr('title').toLowerCase().indexOf(value) > -1)
      });
    });


    $("#recensioni_lista #lista_form #ricerca_nome").on("keyup", function() {
      var value = $(this).val().toLowerCase();
      console.log(value);
      $("#table_recensioni_ajax table tr").filter(function() {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
      });
    });

    $("#pietremiliari_lista #lista_form #ricerca_nome").on("keyup", function() {
      var value = $(this).val().toLowerCase();
      console.log(value);
      $(".table_lista table tr").filter(function() {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
      });
    });

    $("#sezioni #lista_form #ricerca_nome").on("keyup", function() {
      var value = $(this).val().toLowerCase();
      console.log(value);
      $(".no_table_list > .cell").filter(function() {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
      });
    });

    $("#concerti #lista_form #ricerca_nome").on("keyup", function() {
      var value = $(this).val().toLowerCase();
      console.log(value);
      $(".table_lista table tr").filter(function() {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
      });
    });

    $("#archivio #lista_form #ricerca_nome").on("keyup", function() {
      var value = $(this).val().toLowerCase();
      console.log(value);
      $(".table_lista table tr").filter(function() {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
      });
    });

    $("#archivio_interviste #lista_form #ricerca_nome").on("keyup", function() {
      var value = $(this).val().toLowerCase();
      console.log(value);
      $(".table_lista table tr").filter(function() {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
      });
    });

    $("#archivio_artisti #lista_form #ricerca_nome").on("keyup", function() {
      var value = $(this).val().toLowerCase();
      console.log(value);
      $(".no_table_list > .cell").filter(function() {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
      });
    });

  // function mapList(response){

  //   $.each(response, function (i, row) {

  //     var table_row = '<tr onclick="window.location='+row.url+'">';
  //     table_row += '<td class="fixed image_titolo_genere"><div class="container_info"><img src="'+row.image+'"><span class="titolo_container"><span class="autore">'+row.autore+'</span><span class="titolo">'+row.titolo+'</span></span><span class="genere">'+row.genere+'</span></div></td>';
     
  //     $.each(row.voti, function (r, subrow) {
  //       var voto = subrow.recensore.voto === null ? '' : subrow.recensore.voto; 
  //       table_row += '<td class="voto"><span alt="'+subrow.recensore.nome+'" title="'+subrow.recensore.nome+'">'+voto+'</span></td>';
  //     });
      
  //     table_row += '<td class="fixed media_consigliato"><span class="media '+row.media.class+'">'+row.media.voto+'</span><span class="consigliato"></span></td>';
  //     table_row += '</tr>';

  //     $("#table_recensioni_ajax table tbody").append(table_row);

  //   });
  // }

});


